@font-face {
  font-family: Inter Regular;
  src: local("Inter Regular"), url(./Assets/media/Inter-Regular.2391725004ae05a27e8e.ttf) format("truetype"), url(./Assets/media/Inter-Regular.38189b81cda9cc5cc36e.woff) format("woff"), url(./Assets/media/Inter-Regular.afe58fb8735be21e0934.eot)
}

@font-face {
  font-family: Inter Medium;
  src: local("Inter Medium"), url(./Assets/media/Inter-Medium.e7231ba33760832432b8.ttf) format("truetype"), url(./Assets/media/Inter-Medium.6d27836c04f45b58e13a.woff) format("woff"), url(./Assets/media/Inter-Medium.1b052cd51dac01ce0b8f.eot)
}

@font-face {
  font-family: Inter SemiBold;
  src: local("Inter SemiBold"), url(./Assets/media/Inter-SemiBold.45b4c2ae493bb481102f.ttf) format("truetype"), url(./Assets/media/Inter-SemiBold.3bf727097cf39323738d.woff) format("woff"), url(./Assets/media/Inter-SemiBold.ae2944957f2d4bbf04bb.eot)
}

@font-face {
  font-family: Inter Bold;
  src: local("Inter Bold"), url(./Assets/media/Inter-Bold.a73db89c43e44ca8f5e0.ttf) format("truetype"), url(./Assets/media/Inter-Bold.faa92acfd277b0ceee73.woff) format("woff"), url(./Assets/media/Inter-Bold.e9f579fc1d09ef3eb18d.eot)
}

* {
  box-sizing: border-box
}

body,
html {
  -webkit-text-size-adjust: 100%
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  background: #ebf4fc;
  font-family: Inter Regular, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  /* user-select: none; */
}

::-webkit-scrollbar {
  height: 9px;
  width: 8px
}

::-webkit-scrollbar-track {
  background: #d9dcd7
}

::-webkit-scrollbar-thumb {
  background: #346ba1;
  border-radius: 3px
}

::-webkit-scrollbar-thumb:hover {
  background: #337ec3
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

input[type=number] {
  -moz-appearance: textfield
}

a {
  color: inherit;
  text-decoration: none
}

.flexbox-fix div:first-child {
  z-index: 0
}

a:hover {
  color: inherit;
  cursor: pointer;
  text-decoration: none
}

h1 {
  color: #303337;
  font-weight: 400;
  font-size: 28px !important;
  font-family: Inter Bold;
  line-height: 1.167;
}

h2 {
  color: #303337;
  font-size: 26px !important;
}

h3 {
  font-size: 19px !important;
  font-family: Inter Regular;
  font-weight: 700 !important;
  line-height: 1.167 !important;
  color: rgb(76, 81, 86) !important;
  word-break: break-all;
}

h4 {
  color: #292d2f;
  margin-top: 8px;
  line-height: 22px;
  font-size: 14px !important;
  font-family: Inter Regular;
  font-weight: 400;
}

p {
  font-size: 16px;
  font-family: Inter Regular;
  font-weight: 400;
  line-height: 1.43;
}

.card {
  padding: 32px;
  background: #fff;
  box-shadow: 0 2px 10px 5px rgba(37, 40, 44, 0.1);
  border-radius: 16px;
}

.blue__text {
  color: #3374b6;
  margin-left: 8px;
}

.auth__width {
  width: 70%;
  margin-top: 3rem;
}

.link__text {
  color: #3374b6 !important;
  cursor: pointer;
  font-family: Inter Medium;
  font-weight: 500;
  padding-left: 5px;
  text-decoration: none;
}

.auth__button {
  color: #fff;
  height: 45px;
  box-shadow: none;
  font-weight: 600;
  border-radius: 45px;
  text-transform: initial;
  background-color: #3374b6;
}

.input__ {
  flex: 1;
  min-height: 45px;
  border-radius: 25px;
  border: 1px solid #dbdedf;
}

.aggrement>span:last-child {
  color: #292d2f;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.DebounceInput {
  font: inherit;
  color: #6b757f;
  border: 0;
  margin: 0;
  font-family: Inter Regular;
  outline: none;
  border-radius: 35px;
  padding-left: 15px;
  height: 45px;
  border: 1px solid #dadada;
}

.filter {
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dadada;
}

.sort {
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid #dadada;
}

.proLibCard {
  width: 100%;
  display: flex;
  box-shadow: 0 5px 9px 3px rgba(37, 40, 44, 0.1);
  /* min-height: 156px; */
  border-radius: 10px !important;
  margin-bottom: 1rem;
}

.faq_side_card {
  background: #fff;
  min-height: 25rem;
  margin-left: -12px;
  padding: 1rem 1rem 1rem 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 20px rgb(38 41 44 / 7%);
}

.rounded__left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded__right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.active__class {
  border: 1px solid rgb(232, 238, 235);
  background-color: rgb(230, 255, 242);
  color: rgb(117, 202, 158);
  min-width: 6rem !important;
}

.deactivated__class {
  border: 1px solid rgb(237, 233, 220);
  background-color: rgb(250, 229, 229);
  color: rgb(250, 90, 89);
  min-width: 6rem !important;
}

.pending__class {
  border: 1px solid rgb(237, 233, 220);
  background-color: rgb(250, 245, 230);
  color: rgb(226, 180, 50);
  min-width: 6rem !important;
}

.excelUpload {
  padding: 12px;
  background: #f3f3f3;
  list-style: none;
  border-radius: 10px;
  margin-top: 8px;
}

/* ANTD GLOBAL CLASSES */
.ant-menu-light .ant-menu-item-selected {
  color: #3374b6 !important;
  box-shadow: inset 0 2px 3px rgba(67, 71, 75, 0.07), inset 0 0 0px rgba(43, 46, 50, 0.07);
  background-color: #e3eaf1;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  height: 50px !important;
}

.ant-avatar>img {
  object-fit: contain;
}

li.ant-menu-item.ant-menu-item-only-child {
  padding-left: 12px !important;
}

.ant-menu-inline .ant-menu-item {
  height: 60px;
  line-height: 60px;
}

.ant-layout .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  display: none;
}

svg {
  outline: none !important;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #8aa7cf;
  border-radius: 50%;
}

.ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-1ck3jst).ant-table-wrapper .ant-table-thead>tr>td {
  background: #f2f8fd;
}

.ant-select-selector {
  border-radius: 39px !important;
  height: 50px;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}

.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  margin-top: 11px;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 25px;
}

.ant-upload.ant-upload-select {
  width: -webkit-fill-available;
}

/* Jodit Editor */
.jodit-status-bar .jodit-status-bar__item.jodit-status-bar__item-right {
  display: none;
}

.jodit-add-new-line {
  display: none !important;
}

@media (max-width: 992px) {
  .auth__width {
    width: 95%;
    margin-top: 0rem;
  }

  .DebounceInput {
    padding-left: 15px;
    max-width: 200px;
    margin-bottom: 12px;
  }

  .proLibCard {
    flex-wrap: wrap;
  }
}

.active_setting {
  color: #fff;
  height: 45px;
  box-shadow: none;
  font-weight: 600;
  text-transform: initial;
  background-color: #3374b6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inactive_setting {
  /* color: #fff; */
  height: 45px;
  box-shadow: none;
  font-weight: 600;
  border-radius: 0 45px 45px 0;
  text-transform: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  cursor: pointer;


}

.Cart_img img {
  object-fit: contain;
}

.border-btn {
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 1.5rem 2rem;
  color: #555;
}

.text-right {
  text-align: right;
}

.modal-h3 {
  color: black !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}

.model, .sticker, .text ,.color{
  border: 1px solid #dfe1e2;
  margin-bottom: 15px;
  padding: 15px!important;
  border-radius: 4px;
}

.text-box input{
  border-radius: 0!important;
}

.font .ant-select-selector{
  border-radius: 0!important;
}

.size .ant-select-selector{
  border-radius: 0!important;
}

.other img{
  width: 30px!important;
}


.align-group,
.pattern-group,
.other-group {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.other-group .result {
  display: flex;
  gap: 5px;
}

.other-group .crop {
  border: 1px solid #ccc;
}


.layers {
  /* position: absolute; */
  /* bottom: 6rem; */
  width: 100%;
  background: white;
}

.layers .layer.active {
  background: #ccc;
}

.layers .layer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 20px;
  margin: 0.5px 0px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.layers .layer .label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.layers .layer .label img {
  width: 20px;
  border: 1px solid #aaa;
  border-radius: 50%;
}

.layers .layer .more1 img.more {
  width: 25px;
}

.more1 .locked {
  width: 15px;
}

.layers .layer .more2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  right: -45px;
  bottom: 0;
}

.more2 .delete,
.more2 .lock {
  display: flex;
  align-items: center;
  gap: 5px;
}

.layers .layer .more2 img {
  width: 20px;
}

.layers .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #eee;
  padding: 20px 10px;
}

.layers .status .symbol {
  display: flex;
  align-items: center;
  gap: 5px;
}

.layers .status .symbol img {
  width: 20px;
}

.layers .status .count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background: skyblue;
  border-radius: 50%;
}

.crop-container {
  position: absolute;
  display: inline-block;
  cursor: move;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
}

.crop-handle-ne,
.crop-handle-se,
.crop-handle-nw,
.crop-handle-sw {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  background: rgba(222, 60, 80, .9);
  z-index: 999;
}

.crop-handle-nw {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}

.crop-handle-sw {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.crop-handle-ne {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.crop-handle-se {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}

.recomended-size .ant-table-content{
  border: 1px solid lightgray !important;
  border-radius: 15px;
  padding: 15px;
  font-size: 12px;
}
.recomended-size{
text-transform: uppercase;
}
.recomended-size tr td{
height: 15px!important;
}

.recomended-size td:first-child {
  /* Add your styles for the first child of each td here */
  font-weight: bold;
  color: #dd2827; /* Example color */
}